<template>
  <div class="main-section mt-3" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
    <div class="section-notice notice-primary">
      <img src="../../../../src/assets/img/main/online_sb.png" style="width: 100%;height:100%;position: absolute;">
      <a href="/support" class="notice-link" title="새 창" style="justify-content: space-between">
      <div class="title-area" style="padding: 0">
        <small class="bold small-text">온라인 판로 지원사업</small>
        <h4 class="title">다양한 판로 지원사업을<br>확인하세요</h4>
      </div>
        <i class="icon-link">소상공인 지원사업 이동하기</i>
      </a>
    </div>
      <!-- 구독경제 -->
    <div class="section-notice section-item">
      <img src="../../../../src/assets/img/main/subscribe.png" style="width: 100%;height:100%;position: absolute;object-fit: cover;">
      <div class="notice-section-deco">
        <small class="bold small-text deco-text" style="font-size: 17px">소상공인 구독경제 길라잡이</small>
        <i class="icon-link-area"></i>
      </div>
      <swiper v-if="isSlideReady" ref="mySwiper" class="notice-list"
              :options="swiperOptions"
              @slideChange="changeCommunity"
      >
        <swiper-slide class="notice-item" v-for="(item, idx) in items" :key="idx">
          <router-link :to="`/community/${item.comunBltartclId}/view?ctgrCode=1007007`" class="notice-link" title="새 창">

          </router-link>
          <div class="title-area">
            <small class="bold small-text">&nbsp;</small>
            <h4 class="title">{{ item.comunBltartclTit }}</h4>
          </div>
        </swiper-slide>
      </swiper>
      <ol class="section-notice-paging">
        <li v-for="(item, idx) in items" :class="{'selected' : idx === currentCommunity}" :key="idx">{{idx + 1}}</li>
      </ol>
    </div>

  </div>
</template>
<script>


import {ACT_GET_SB_COMMUNITY_LIST} from '../../../store/_act_consts';
import {getItems, lengthCheck} from '../../../assets/js/utils';
import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
import {swiperOptions} from '../../../assets/js/swiper.config';
import {aosInit} from '../../../assets/js/aos.config';

export default {
  name: 'MobileSection1',
  components:{
    Swiper,
    SwiperSlide
  },
  data: ()=> ({
    items: [],
    // currentIssue: 0,
    currentCommunity: 0,
    swiperOptions: swiperOptions({speed:600, autoplay:false}),
    isSlideReady: false,
  }),
  computed:{
    swiper() {
      return this.$refs.mySwiper.$swiper;
    }
  },
  created() {
    aosInit();
    // this.getIssueList();
    this.getSbCommunityList();
  },
  methods: {
    // getIssueList() {
    //   this.$store.dispatch(`information/${ACT_GET_ISSUE_LIST}`, {
    //     pageSize: 3,
    //   }).then(res=>{
    //     if(lengthCheck(res)){
    //       this.items = getItems(res);
    //     } else {
    //       this.items = [];
    //     }
    //     this.isSlideReady = true;
    //   }).catch(e=>{
    //     console.error(e);
    //     this.items = [];
    //   })
    // },
    getSbCommunityList() {
      this.$store.dispatch(`community/${ACT_GET_SB_COMMUNITY_LIST}`, {
        pageSize: 4,
        comunCtgrDcd: 1007007
      }).then(res=>{
        if(lengthCheck(res)){
          this.items = getItems(res);
        } else {
          this.items = [];
        }
        this.isSlideReady = true;
      }).catch(e=>{
        console.error(e);
        this.items = [];
      })
    },
    changeCommunity(){
      this.currentCommunity = this.swiper.realIndex;
    },
    // changeIssue(){
    //   this.currentIssue = this.swiper.realIndex;
    // },
  }
}
</script>