<template>
  <div>
    <div class="main-section section-service">
      <ul class="major-service-list">
        <li class="major-service-item">
          <router-link to="/broadcast" class="service-link">
            <div class="service-content">
              <div class="title-area">
                <small class="bold small-text">맞춤형 판로 소통방송을 소개합니다.</small>
                <h4 class="title">판로TV</h4>
              </div>
            </div>
            <div class="service-icon">
              <i class="icon-service-broadcast"></i>
            </div>
          </router-link>
        </li>
        <li class="major-service-item">
          <router-link to="/issue" class="service-link">
            <div class="service-content">
              <div class="title-area">
                <small class="bold small-text">온라인 시장 트렌드가 궁금하세요?</small>
                <h4 class="title">디지털 뉴스</h4>
              </div>
            </div>
            <div class="service-icon">
              <i class="icon-service-news"></i>
            </div>
          </router-link>
        </li>
        <li class="major-service-item">
          <router-link to="/studio" class="service-link">
            <div class="service-content">
              <div class="title-area">
                <small class="bold small-text">라이브커머스 공간을 빌려드립니다.</small>
                <h4 class="title">스튜디오 안내 및 예약</h4>
              </div>
            </div>
            <div class="service-icon">
              <i class="icon-service-studio"></i>
            </div>
          </router-link>
        </li>
        <li class="major-service-item">
          <a href="javascript:" class="service-link" @click="clickService">
            <div class="service-content">
              <div class="title-area">
                <small class="bold small-text">라이브커머스 역량강화 교육서비스</small>
                <h4 class="title">온라인 진출 배움터</h4>
              </div>
            </div>
            <div class="service-icon">
              <i class="icon-service-edu"></i>
            </div>
          </a>
        </li>
        <li class="major-service-item">
          <router-link to="/support" class="service-link">
            <div class="service-content">
              <div class="title-area">
                <small class="bold small-text">전국 소상공인을 위한</small>
                <h4 class="title">온라인 판로지원사업안내</h4>
              </div>
            </div>
            <div class="service-icon">
              <i class="icon-service-connect"></i>
            </div>
          </router-link>
        </li>
        <li class="major-service-item">
          <router-link to="/community?ctgrCode=1007001" class="service-link">
            <div class="service-content">
              <div class="title-area">
                <small class="bold small-text">온라인 시장 진출을 도와드립니다.</small>
                <h4 class="title">디지털 전환 솔루션</h4>
              </div>
            </div>
            <div class="service-icon">
              <i class="icon-service-community"></i>
            </div>
          </router-link>
        </li>
        <li class="major-service-item">
          <a :href="marketUrl" class="service-link" target="_blank">
            <div class="service-content">
              <div class="title-area">
                <small class="bold small-text">우수 소상공인 상품을 판매합니다.</small>
                <h4 class="title">가치삽시다 마켓</h4>
              </div>
            </div>
            <div class="service-icon">
              <i class="icon-service-live"></i>
            </div>
          </a>
        </li>
        <!--<li class="major-service-item">
          <router-link :to="{name: 'Foothold'}" class="service-link">
            <div class="service-content">
              <div class="title-area">
                <small class="bold small-text">인증된 인플루언서와 함께하는</small>
                <h4 class="title">맞춤 인플루언서 찾기</h4>
              </div>
            </div>
            <div class="service-icon">
              <i class="icon-service-connect"></i>
            </div>
          </router-link>
        </li>-->
      </ul>
    </div>
    <mobile-target-section v-for="(item, idx) in sectionGroups" :sect-grp-id="item.sectGrpId" :key="idx"/>
  </div>
</template>
<script>
import MobileTargetSection from './MobileTargetSection';
import {mapGetters} from 'vuex';
import {ACT_GET_SECTION_GROUP_LIST} from '../store/_act_consts';
import {getItems, lengthCheck, tryResCallback} from '../assets/js/utils';
import {MUT_SET_SECTION_GROUPS} from '../store/_mut_consts';
import {aosInit} from '../assets/js/aos.config';

export default {
  name: 'MobileSectionGroup',
  components: {MobileTargetSection},
  data: () => ({
    marketUrl: `${process.env.VUE_APP_GACHI_MALL_HOST}`,
    tryCount: 0
  }),
  async created() {
    aosInit();
    this.getSectionGroups();
  },
  computed: {
    ...mapGetters('main', ['sectionGroups', 'sectionExpired'])
  },
  methods: {
    getSectionGroups() {
      if(this.sectionGroups.length === 0 || new Date().getTime() > this.sectionExpired){
        this.$store.dispatch(`main/${ACT_GET_SECTION_GROUP_LIST}`).then(res => {
          tryResCallback(this.tryCount, res, this.getSectionGroups, this.setSectionGroups);
        }).catch(e => {
          console.log(e);
        });
      }
    },
    setSectionGroups(res) {
      if(lengthCheck(res)){
        const items = getItems(res);
        this.$store.commit(`main/${MUT_SET_SECTION_GROUPS}`, items.map(x => ({sectGrpId: x.sectGrpId})));
      }
    },
    clickService(){
      this.$router.push({name:'Eduction'})
    }
  }
}
</script>
