<template>
  <!-- 온라인 판로진출 -->
  <div class="main-section section-component">
    <div class="section-online section-item" data-scale="white">
      <div class="title-area">
        <small class="bold small-text">온라인 판로진출, 기반 조성</small>
        <h4 class="title">소상공인 입점신청</h4>
      </div>
      <router-link :to="'mypage/start'" class="btn btn-white btn-apply">
        <span class="text text-primary">신청하기</span>
      </router-link>
      <div class="section-online-image">
        <img src="../../assets/img/main/section_online.jpg" alt="">
      </div>
    </div>
    <!-- 크리에이터 교육신청 -->
    <div class="section-online section-item" data-scale="dark">
      <div class="title-area">
        <h4 class="title">SNS속 소상공인 이야기</h4>
      </div>
      <router-link :to="{name: 'Issue2'}" class="btn btn-white btn-apply">
        <span class="text text-primary"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">바로보기</font></font></span>
      </router-link>
      <div class="section-online-image">
        <img src="../../assets/img/main/section_apply.jpg" alt="">
      </div>
    </div>
  </div>
</template>
<script>
import {MUT_SHOW_ALERT} from '../../store/_mut_consts';

export default {
  name: 'Section6',
  methods: {
    clickApply(){
      this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
        title: '준비중입니다.'
      });
    }
  }
}
</script>