<template>
  <div class="main-section" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
    <swiper class="section-information" ref="mySwiper" :options="swiperOptions"
    >
      <article class="notice-card notice-infoplayground swiper-slide">
        <router-link to="/playground?ctgrCode=1001001" class="card-link"><span class="screen-out">자세히 보기</span></router-link>
        <!--          <a href="" class="card-link"><span class="screen-out">자세히 보기</span></a>-->
        <div class="card-inner">
          <header class="card-header">
            <h4 class="header" style="color: black">소상공인 뉴스</h4>
          </header>
          <div class="card-body">
            <!-- <h5 class="title">{{ playground.plygrndTit }}</h5>
            <p class="tag">{{ playground.tags }}</p> -->
          </div>
        </div>
        <div class="card-image">
          <!--<img :src="playground.rprsImgPtUrl" alt="">-->
          <img src="../../../assets/img/main/funnycontents.png" alt="">
        </div>
      </article>
      <article class="notice-card notice-infoplayground swiper-slide">
        <router-link :to="{name: 'Issue'}"  class="card-link"><span class="screen-out">자세히 보기</span></router-link>
        <!--          <a href="" class="card-link"><span class="screen-out">자세히 보기</span></a>-->
        <div class="card-inner">
          <header class="card-header">
            <h4 class="header" style="color: black">소상공인 포스트</h4>
            <div class="issue">
              <!-- <small class="issue-title">issue</small>
              <p class="issue-num">{{ issue.issueNo }}</p> -->
            </div>
          </header>
          <div class="card-body">
            <!--<h5 class="title">{{ issue.issueTit }}</h5>
            <p class="view">
              <i class="icon-view">main/view_icon.svg</i>
              <span class="text">{{ issue.inqCnt }}</span>
            </p>-->
          </div>
        </div>
        <div class="card-image">
          <!--<img :src="issue.rprsImgPtUrl" alt="">-->
          <img src="../../../assets/img/main/sp_renew_mb.png" alt="">
        </div>
      </article>
    </swiper>
  </div>

</template>
<style>
  /*.section-information .swiper-slide {width:85% !important;}*/
</style>
<script>
import {ACT_GET_ISSUE_LIST, ACT_GET_PLAYGROUND_LIST} from '../../../store/_act_consts';
import {getItem, lengthCheck, numberComma, setParams, viewTags} from '../../../assets/js/utils';

import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
import {swiperOptions} from '../../../assets/js/swiper.config';
import {aosInit} from '../../../assets/js/aos.config';

export default {
  name: 'MobileSection5_2',
  components: {
    Swiper,
    SwiperSlide
  },
  data:() => ({
    playground: {
      plygrndId: 0,
      plygrndCtgr: '',
      plygrndTit: '',
      rprsImgPtUrl: '',
      tags: '',
    },
    issue: {
      issueId: 0,
      issueNo: 0,
      issueTit: '',
      issueSmlTit: '',
      rprsImgPtUrl: '',
      inqCnt: 0
    },
    swiperOptions: swiperOptions({spaceBetween: 15, speed:600})
  }),
  created() {
    aosInit();
    this.getPlaygroundTop();
    this.getIssueTop();
  },
  methods: {
    getPlaygroundTop(){
      this.$store.dispatch(`information/${ACT_GET_PLAYGROUND_LIST}`, {
        pageSize: 1
      }).then(res=>{
        if(lengthCheck(res)){
          const item = getItem(res);
          setParams(this.playground, item);
          this.playground.tags = viewTags(item, 'plygrndTag');
        } else {
          this.playground.plygrndId = 0;
        }
      }).catch(e=>{
        console.error(e);
      });
    },
    getIssueTop(){
      this.$store.dispatch(`information/${ACT_GET_ISSUE_LIST}`, {
        pageSize: 1
      }).then(res=>{
        if(lengthCheck(res)){
          const item = getItem(res);
          setParams(this.issue, item);
          if(item.inqCnt != null) {
            this.issue.inqCnt = numberComma(item.inqCnt);
          }
        } else{
          this.issue.issueId = 0;
        }
      }).catch(e=>{
        console.error(e);
      })
    }
  }
}
</script>