<template>
	<div class="fullpage-container">
		<div class="mouse-scroll" v-show="!isMobile"><span>scroll</span></div>
		<!-- indicator -->
		<div class="fullpage-pagination" v-show="!isMobile">
			<button type="button" class="fp-pagination-item" :class="{ 'is-active': activeSection === index }" @click="scrollToSection(index)" v-for="(offset, index) in offsets" :key="index">
				<i></i>
				<span>{{ pagination[index] }}</span>
			</button>
		</div>
		<!-- //indicator -->
		<slot></slot>
		<!-- 풀페이지용 top 버튼  -->
		<!-- <button type="button" class="fullpage-btn-top" @click="scrollToSection(0)"><i class="icon-quick icon-quick-top"></i><span class="sr-only">TOP</span></button> -->
	</div>
</template>
<script>
/*
.fp-normal-scroll : fullpage 스크롤이 작동하는 영역내에서 스크롤이 필요한 영역내에 이 클래스를 할당하면 스크롤 작동
*/
export default {
	name: 'FullPage',
	props: {
		isMobile: {
			type: Boolean,
			default: false,
		},
		loop: {
			type: Boolean,
			default: false,
		},
		pagination: {
			type: Array,
			default: [],
		},
	},
	components: {},
	computed: {},
	data: () => ({
		inMove: false,
		inMoveDelay: 400,
		activeSection: 0,
		offsets: [],
		lightOffsets: [],
		footerOffsets: [],
		partnerOffsets: [],
		touchStartY: 0,
	}),
	methods: {
		onResize() {
			// $tablet : 1327px
			if (!this.isMobile && window.innerWidth > 1327) {
				this.calculateSectionOffsets();
				this.scrollToSection(this.activeSection, true);
				this.addEventListener();
			} else {
				this.offsets = [];
				this.removeEventListener();
			}
		},
		/**
		 * Calcaulates the absolute offsets of each section on the page and pushs it into the offsets array
		 */
		calculateSectionOffsets() {
			this.offsets = [];
			let sections = document.getElementsByClassName('fullpage');
			let length = sections.length;

			for (let i = 0; i < length; i++) {
				let sectionOffset = sections[i].offsetTop;
				this.offsets.push(sectionOffset);

				if (sections[i].classList.contains('__fp-light')) {
					this.lightOffsets.push(i);
				}
				if (sections[i].classList.contains('fullpage-footer')) {
					this.footerOffsets.push(i);
				}
				if (sections[i].classList.contains('fullpage-partner')) {
					this.partnerOffsets.push(i);
				}
			}
		},
		/**
		 * Handle the 'mousewheel' event for other browsers
		 */
		handleMouseWheel(e) {
			if (!e.target.closest('.fp-normal-scroll')) {
				if (e.wheelDelta < 30 && !this.inMove) {
					this.moveUp();
				} else if (e.wheelDelta > 30 && !this.inMove) {
					this.moveDown();
				}

				e.preventDefault();
				return false;
			}
		},
		/**
		 * Handle the 'DOMMouseScroll' event for Firefox
		 */
		handleMouseWheelDOM(e) {
			if (!e.target.closest('.fp-normal-scroll')) {
				if (e.detail > 0 && !this.inMove) {
					this.moveUp();
				} else if (e.detail < 0 && !this.inMove) {
					this.moveDown();
				}

				return false;
			}
		},
		/*
		 * fullpage 섹션이 light 인지를 확인해서 fullpage-pagination 의 색상을 결정
		 */
		checkPagination() {
			if (this.lightOffsets.indexOf(this.activeSection) < 0) {
				document.querySelector('html').classList.remove('__fullpage-light');
			} else {
				document.querySelector('html').classList.add('__fullpage-light');
			}
			if (this.footerOffsets.indexOf(this.activeSection) < 0) {
				document.querySelector('html').classList.remove('__fullpage-footer');
			} else {
				document.querySelector('html').classList.add('__fullpage-footer');
			}
			if (this.partnerOffsets.indexOf(this.activeSection) < 0) {
				document.querySelector('html').classList.remove('__fullpage-partner');
			} else {
				document.querySelector('html').classList.add('__fullpage-partner');
			}
		},
		/**
		 * Move to the previous section or the last section if you're on the first section
		 */
		moveDown() {
			this.inMove = true;
			this.activeSection--;

			// loop prop 이 true일 경우 처음과 마지막 섹션에서 loop 처리
			if (this.loop) {
				if (this.activeSection < 0) this.activeSection = this.offsets.length - 1;
			} else {
				if (this.activeSection < 0) this.activeSection = 0;
			}

			this.scrollToSection(this.activeSection, true);
		},
		/**
		 * Move to the next section or the first section if you're on the last section
		 */
		moveUp() {
			this.inMove = true;
			this.activeSection++;

			// loop prop 이 true일 경우 처음과 마지막 섹션에서 loop 처리
			if (this.loop) {
				if (this.activeSection > this.offsets.length - 1) this.activeSection = 0;
			} else {
				if (this.activeSection > this.offsets.length - 1) this.activeSection = this.offsets.length - 1;
			}
			this.scrollToSection(this.activeSection, true);
		},
		/**
		 * Scrolls to the passed section id if the section exists and the delay is over
		 */
		scrollToSection(id, force = false) {
			if (this.inMove && !force) return false;

			this.activeSection = id;
			this.inMove = true;

			// get section and scroll into view if it exists
			let section = document.getElementsByClassName('fullpage')[id];
			if (section) {
				document.getElementsByClassName('fullpage')[id].scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'center' });
			}

			this.checkPagination();

			setTimeout(() => {
				this.inMove = false;
			}, this.inMoveDelay);
		},
		/**
		 * Handles the 'touchstart' event on mobile devices
		 */
		touchStart(e) {
			if (!e.target.closest('.fp-normal-scroll')) {
				e.preventDefault();

				this.touchStartY = e.touches[0].clientY;
			}
		},
		/**
		 * Handles the 'touchmove' event on mobile devices
		 */
		touchMove(e) {
			if (!e.target.closest('.fp-normal-scroll')) {
				if (this.inMove) return false;
				e.preventDefault();

				const currentY = e.touches[0].clientY;

				if (this.touchStartY < currentY) {
					this.moveDown();
				} else {
					this.moveUp();
				}

				this.touchStartY = 0;
				return false;
			}
		},
		/**
		 * 스크롤 이벤트 발생시 activeSection 설정
		 */
		handleListScroll(e) {
			if (this.activeSection === 0 && window.scrollY > 930) {
				// console.log('뒤로가기 스크롤 유지');
				// offset 에 있는 값중에 가장 가까운 값의 인덱스로 activeSection 설정
				const targetIdx = this.offsets.reduce((prev, curr) => {
					return Math.abs(curr - window.scrollY) < Math.abs(prev - window.scrollY) ? curr : prev;
				});
				this.activeSection = this.offsets.indexOf(targetIdx) - 1;
			}
		},
		addEventListener() {
			window.addEventListener('DOMMouseScroll', this.handleMouseWheelDOM); // Mozilla Firefox
			window.addEventListener('mousewheel', this.handleMouseWheel, { passive: false }); // Other browsers
			if (this.isMobile) {
				window.addEventListener('touchstart', this.touchStart); // mobile devices
				window.addEventListener('touchmove', this.touchMove); // mobile devices
			}
			// 스크롤 이벤트 발생시 activeSection 설정
			window.addEventListener('scroll', this.handleListScroll);
		},
		removeEventListener() {
			window.removeEventListener('DOMMouseScroll', this.handleMouseWheelDOM); // Mozilla Firefox
			window.removeEventListener('mousewheel', this.handleMouseWheel, { passive: false }); // Other browsers
			if (this.isMobile) {
				window.removeEventListener('touchstart', this.touchStart); // mobile devices
				window.removeEventListener('touchmove', this.touchMove); // mobile devices
			}
			// 스크롤 이벤트 발생시 activeSection 설정
			window.removeEventListener('scroll', this.handleListScroll);
		},
	},
	/**
	 * mounted() hook executes after page load and call the section offset calculation and registers all events
	 */
	mounted() {
		this.$nextTick(() => {
			document.querySelector('html').classList.add('__fullpage');
			if (this.isMobile) {
				document.querySelector('html').classList.add('__mobile');
			}
			this.onResize();
			window.addEventListener('resize', this.onResize);

			// if (!this.isMobile && window.innerWidth > 1327) {
			// 	this.calculateSectionOffsets();
			// 	this.addEventListener();
			// } else {
			// 	this.offsets = [];
			// 	this.removeEventListener();
			// }
		});
	},

	/**
	 * destroyed() hook executes on page destroy and removes all registered event listeners
	 */
	destroyed() {
		this.removeEventListener();
		document.querySelector('html').classList.remove('__fullpage');
	},
	beforeDestroy() {
		window.removeEventListener('resize', this.onResize);
	},
};
</script>
