<template>
  <div class="main-section"data-aos="fade-up" data-aos-anchor-placement="top-bottom">
    <div class="section-studio">
      <router-link to="/studio" class="cover-link">
        <span class="screen-out">스튜디오 예약 상세보기</span>
      </router-link>
      <div class="title-area">
        <small class="bold small-text">스튜디오 안내 및 예약</small>
        <h4 class="title">소상공인 전용<br>라이브 스튜디오 예약하기</h4>
      </div>
      <div class="section-studio-image">
        <img src="../../../assets/mobile/img/main/section_studio.jpg" alt="" />
      </div>
    </div>
  </div>
</template>
<script>
import {aosInit} from '../../../assets/js/aos.config';

export default {
  name: 'MobileSection3',
  created() {
    aosInit();
  }
}
</script>