<template>
  <!-- 인증크리에이터 거점연결 -->
  <div class="main-section section-component">
    <div class="section-creator section-item">
      <div class="title-area">
        <small class="bold small-text">인증 인플루언서 소개</small>
        <h4 class="title">믿을 수 있는 인플루언서를 소개합니다.</h4>
      </div>
      <div class="section-creator-creators">
        <!-- [dev] style="..." 스크롤 애니메이션을 위한 임시 요소 -->
        <ul class="creator-list" style="box-shadow : 0px 7px 0 0px #dfdfdf inset">
          <template v-for="(option, idx) in ytbChnlCtgrTypCdOptions">
            <li class="creator-item section" :key="idx">
              <div class="creator-image gachi-cursor-pointer">
                <img :src="require(`../../assets/img/community/re_new_create_img${idx+1}.png`)"  @click="goFootholdList(option.cmmnCdVal)"/>
              </div>
              <div class="creator-description">
                <p class="creator">{{ option.cmmnCdValNm }}</p>
              </div>
            </li>

          </template>

<!--
          <template v-for="(item, idx) in topItems">
            <li v-if="item.isBlank" class="creator-item" :key="idx">
              <div class="creator-image"></div>
            </li>

            <li v-else class="creator-item" :key="idx">
              <i v-if="item.certCtrYn === 'Y'" class="icon-authentication">인증</i>
              <div class="creator-image gachi-cursor-pointer">
                &lt;!&ndash; <img
                  v-if="item.rprsImgPtUrl"
                  :src="item.rprsImgPtUrl"
                  alt=""
                  @click="goFoothold($event, item.ctrFthldId)"
                /> &ndash;&gt;
                &lt;!&ndash; <img
                  v-if="item.rprsImgPtUrl"
                  :src="item.rprsImgPtUrl"
                  alt=""
                  @click="clickMove"
                /> &ndash;&gt;
                &lt;!&ndash; <img
                  v-else
                  src="../../assets/img/community/reply_unknown_icon.svg"
                  alt=""
                /> &ndash;&gt;

                <img :src="require(`../../assets/img/community/create_img${idx+1}.png`)"  @click="goFootholdList()"/>
                &lt;!&ndash; <img :src="require(`../../assets/img/community/create_img${idx+1}.png`)"  @click="clickMove()"/> &ndash;&gt;
              </div>
              <div class="creator-description">
                &lt;!&ndash; <small class="creator-category">{{ item.fthldIntro }}</small> &ndash;&gt;
                <p class="creator">{{ item.fthldNm }}</p>
                &lt;!&ndash; <p class="creator">인플루언서{{ idx + 1 }}</p> &ndash;&gt;
              </div>
            </li>
          </template>-->


        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import {ACT_GET_CREATOR_FOOTHOLD_LIST, ACT_GET_SHOP_MALL_COMMON_CODE_LIST} from "../../store/_act_consts";
import {getCheckItems, getItems, lengthCheck} from "../../assets/js/utils";
import {MUT_SET_COMMON_CODE_ITEMS, MUT_SHOW_ALERT} from "@/store/_mut_consts";
import {mapGetters} from "vuex";

export default {
  name: "Section4",
  data: () => ({
    items: [],

  }),
  created() {
    this.getCreateFootholds();
    this.getCommonCdList();
  },
  computed: {
    ...mapGetters('common', ['isMobile', 'commonCode']),
    topItems() {
      if (this.items.length < 4) {
        const result = [];
        result.push({ isBlank: true });
        if (this.items.length === 1) result.push({ isBlank: true });
        return result.concat(this.items);
      } else {
        return this.items;
      }
    },
    ytbChnlCtgrTypCdOptions(){
      let result = [];
      result = this.commonCode.filter(x => x.cmmnCd === 'YTB_CHNL_CTGR_TYP_CD');
      return result;
    }
  },
  methods: {
    getCreateFootholds() {
      this.$store
        .dispatch(`community/${ACT_GET_CREATOR_FOOTHOLD_LIST}`, {
          pageSize: 5,
        })
        .then((res) => {
          if (lengthCheck(res)) {
            this.items = getItems(res);
          } else {
            this.items = [];
          }
        })
        .catch((e) => {
          console.error(e);
          this.items = [];
        });
    },
    goFoothold(e, ctrFthldId) {
      if (e.ctrlKey) {
        window.open(`/foothold/${ctrFthldId}/view`);
      } else {
        this.$router.push({ name: "FootholdView", params: { ctrFthldId } });
      }
      // console.log(this.items[idx].ctrFthldId);
    },
    //20220621 추가 인플루언서 클릭 시 리스트로 이동 - hib
    goFootholdList(tag) {
      if(tag === ''){
        this.$router.push({name:'Foothold'});
      }else{
        this.fthldTag = tag;
        this.$router.push({name:'Foothold', query:{tag: this.fthldTag}});
      }
    },
    clickMove() {
      this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
        title: "준비중입니다.",
      });
    },
    getCommonCdList(){
      this.$store.dispatch(`common/${ACT_GET_SHOP_MALL_COMMON_CODE_LIST}`, {}).then(res => {
        this.$store.commit(`common/${MUT_SET_COMMON_CODE_ITEMS}`, getCheckItems(res));
      });
    },
    // refineItems(items){
    //   if(items.length < 4){
    //     const result = [];
    //     result.push({isBlank: true});
    //     if(items.length === 1) result.push({isBlank: true});
    //     this.topItems = result.concat(items);
    //   }else{
    //     this.topItems = items;
    //   }
    // }
  },
};
</script>
