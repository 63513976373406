<template>
  <div class="main-section" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
    <div class="section-creator section-item">
      <div class="title-area">
        <small class="bold small-text">인증 인플루언서 소개</small>
        <h4 class="title">믿을 수 있는<br/>인플루언서를 소개합니다.</h4>
      </div>

<!--      <div @touchstart="swiperStop" @touchend="swiperStart">-->
<!--        <swiper v-if="isSlideReady" class="section-creators swiper js-creator-swiper creator-list" ref="mySwiper" :options="swiperOptions">-->
<!--          <swiper-slide class="creator-item" v-for="(option, idx) in ytbChnlCtgrTypCdOptions" :key="idx">-->
<!--&lt;!&ndash;            <i v-if="item.certCtrYn === 'Y'" class="icon-authentication">인증</i>&ndash;&gt;-->
<!--            <div class="creator-image">-->
<!--              &lt;!&ndash; <img v-if="item.rprsImgPtUrl" :src="item.rprsImgPtUrl" alt="" @click="goFoothold($event, item.ctrFthldId)"> &ndash;&gt;-->
<!--              &lt;!&ndash; <img v-if="item.rprsImgPtUrl" :src="item.rprsImgPtUrl" alt="" @click="clickMove"> &ndash;&gt;-->
<!--              &lt;!&ndash; <img v-else src="../../../assets/img/community/reply_unknown_icon.svg" alt=""> &ndash;&gt;-->

<!--              <img :src="require(`../../../assets/img/community/new_create_img${idx+1}.png`)"  @click="goFootholdList(option.cmmnCdVal)"/>-->
<!--              &lt;!&ndash; <img :src="require(`../../../assets/img/community/create_img${idx+1}.png`)"  @click="clickMove()"/> &ndash;&gt;-->
<!--            </div>-->
<!--            <div class="creator-description" @click="clickMove">-->
<!--              &lt;!&ndash; <small class="creator-category">{{ item.fthldIntro }}</small> &ndash;&gt;-->
<!--              <p class="creator">{{ option.cmmnCdValNm }}</p>-->
<!--              &lt;!&ndash; <p class="creator">인플루언서{{ idx+1 }}</p> &ndash;&gt;-->
<!--            </div>-->
<!--          </swiper-slide>-->
<!--        </swiper>-->
<!--      </div>-->

<!--      인플루언서 영역 변경 22.09.23 pjs-->
      <div>
        <div class="section-creators swiper js-creator-swiper creator-list">
          <div class="creator-item" v-for="(option, idx) in ytbChnlCtgrTypCdOptions" :key="idx" @click="goFootholdList(option.cmmnCdVal)">
            <div class="creator-image">
              <img :src="require(`../../../assets/img/community/re_new_create_img${idx+1}.png`)"/>
            </div>
            <div class="creator-description">
              <p class="creator">{{ option.cmmnCdValNm }}</p>
            </div>
          </div>
        </div>
      </div>


    </div>
  </div>
</template>
<script>
import {ACT_GET_CREATOR_FOOTHOLD_LIST, ACT_GET_SHOP_MALL_COMMON_CODE_LIST} from '../../../store/_act_consts';
import {getCheckItems, getItems, lengthCheck} from '../../../assets/js/utils';
import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
import {aosInit} from '../../../assets/js/aos.config';
import {MUT_SET_COMMON_CODE_ITEMS, MUT_SHOW_ALERT} from "@/store/_mut_consts";
import {mapGetters} from "vuex";

export default {
  name: 'MobileSection4',
  components:{
    Swiper,
    SwiperSlide
  },
  data: () => ({
    items: [],
    swiperOptions:{
      slidesPerView: "auto",
      centeredSlides: true,
      spaceBetween: 20,
      speed:600,
      parallax: true,
      loop: false,
    },
    isSlideReady: false,
    timer: null
  }),
  created() {
    aosInit();
    this.getCreateFootholds();
    this.getCommonCdList();
  },
  computed: {
    ...mapGetters('common', ['isMobile', 'commonCode']),
    //인플루언서 영역 변경 으로 인한 swiper 비활성화 22.09.23
    // swiper() {
    //   return this.$refs.mySwiper.$swiper;
    // },

    ytbChnlCtgrTypCdOptions(){
      let result = [];
      result = this.commonCode.filter(x => x.cmmnCd === 'YTB_CHNL_CTGR_TYP_CD');
      return result;
    }
  },
  methods: {
    getCreateFootholds() {
      this.$store.dispatch(`community/${ACT_GET_CREATOR_FOOTHOLD_LIST}`, {
        pageSize: 5
      }).then(res=>{
        if(lengthCheck(res)){
          this.items = getItems(res);
          if(this.items.length > 1) {
            this.swiperOptions.loop = true;
            this.swiperOptions.autoplay = {
              delay: 1000,
              disableOnInteraction: false,
            }
          }
        } else {
          this.items = [];
        }
        this.isSlideReady = true;
      }).catch(e=>{
        console.error(e);
        this.items = [];
      })
    },
    // goFoothold(e, ctrFthldId){
    //   if(e.ctrlKey){
    //     window.open(`/foothold/${ctrFthldId}/view`);
    //   }else{
    //     this.$router.push({name:'FootholdView', params: {ctrFthldId}});
    //   }
    // },
    //20220621 추가 인플루언서 클릭 시 리스트로 이동 - hib
    goFootholdList(tag) {
      if(tag === ''){
        this.$router.push({name:'Foothold'});
      }else{
        this.fthldTag = tag;
        this.$router.push({name:'Foothold', query:{tag: this.fthldTag}});
      }
    },
    //인플루언서 영역 변경 으로 인한 swiper 비활성화 22.09.23
    // swiperStart(){
    //   if(this.timer){
    //     clearTimeout(this.timer);
    //     this.timer = null;
    //   }
    //
    //   this.timer = setTimeout(()=>{
    //     this.swiper.autoplay.start();
    //   }, 1000);
    // },
    // swiperStop(){
    //   this.swiper.autoplay.stop();
    // },

    // clickMove(){
    //   this.$store.commit(`common/${MUT_SHOW_ALERT}`, {
    //     title: '준비중입니다.'
    //   });
    // },
    getCommonCdList() {
      this.$store.dispatch(`common/${ACT_GET_SHOP_MALL_COMMON_CODE_LIST}`, {}).then(res => {
        this.$store.commit(`common/${MUT_SET_COMMON_CODE_ITEMS}`, getCheckItems(res));
      });
    }
  }
}
</script>