<template>
  <!-- 온라인 판로진출 -->
  <div class="main-section" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
    <div class="section-online section-item" data-scale="white">
      <div class="title-area">
        <small class="bold small-text">온라인 판로진출, 기반 조성</small>
        <h4 class="title">소상공인 <br/>입점신청</h4>
      </div>
      <router-link :to="'mypage/start'" class="btn btn-white btn-apply">
        <span class="text text-primary">신청하기</span>
      </router-link>
      <div class="section-online-image">
        <!-- <a href="" class="image-link"> -->
          <img src="../../../assets/img/main/section_online.jpg" alt="" />
        <!-- </a> -->
      </div>
    </div>
    <!-- 크리에이터 교육신청 -->
    <div class="section-online section-item" data-scale="dark">
      <div class="title-area">
        <h4 class="title">SNS속 소상공인 이야기</h4>
      </div>
      <router-link :to="{name: 'Issue2'}" class="btn btn-white btn-apply">
        <span class="text text-primary">바로보기</span>
      </router-link>
      <div class="section-online-image">
        <!-- <a href="" class="image-link"> -->
          <img src="../../../assets/mobile/img/main/section_apply.jpg" alt="" />
        <!-- </a> -->
      </div>
    </div>
  </div>
</template>
<script>
import {aosInit} from '../../../assets/js/aos.config';

export default {
  name: 'MobileSection6',
  created() {
    aosInit();
  }
}
</script>