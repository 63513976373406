<template>
  <div class="main-banner">
    <div class="banner-component" @mouseover="bannerRolling(false)" @mouseleave="bannerRolling(true)">

      <ol class="banner-text">
        <li v-for="(item, idx) in banners" class="banner-text-item" :class="{'is-visible': idx === currentBanner}" :key="idx">
          <h5 class="banner-sub-title">{{ item.bannrSubTit }}</h5>
          <h1 class="banner-title">{{ item.bannrTit }}</h1>
        </li>
      </ol>

      <ol class="banner-image">
        <!-- <li v-for="(item, idx) in banners" class="banner-image-item" :class="{'is-visible': idx === currentBanner}" :key="`img-${idx}`">
          <img :src="item.imgPtUrl" alt="">
        </li> -->
        <!-- 메인베너 영상노출 S ( 20220721 - hib) -->
        <li class="banner-image-item is-visible" style="width:1280px; height:345px;  ">
          <video width="1280" height="345" loop autoplay muted style="object-fit: fill; width: 640px; height: 350px; margin-left: 640px;" alt="">
            <source src="../assets/img/main/valuebuyIntroduction.mp4" type="video/mp4">
          </video>
        </li>
        <!-- 메인베너 영상노출 E ( 20220721 - hib) -->
      </ol>
        <!-- <div v-if="banners.length > 1" class="banner-paging">
          <span class="current-page">{{ (currentBanner + 1) }}</span>
          <span class="page-bar"></span>
          <span class="total-page">{{ banners.length }}</span>
        </div>

        <div v-if="banners.length > 1" class="banner-move-buttons">
          <button class="banner-move-button prev" type="button" @click="movePage(true)">
            <i class="icon-arrow"></i>
          </button>
          <button class="banner-move-button next" type="button" @click="movePage(false)">
            <i class="icon-arrow"></i>
          </button>
        </div> -->
    </div>

    <div class="banner-background"></div>
  </div>
</template>
<script>
import {mapGetters} from 'vuex';
import {ACT_GET_BANNER_LIST} from '../store/_act_consts';
import {getCheckItems} from '../assets/js/utils';
import {MUT_SET_BANNERS} from '../store/_mut_consts';

export default {
  name: 'Banner',
  components: {},
  data: () => ({
    currentBanner: 0,
    interval: null
  }),
  computed: {
    ...mapGetters('main', ['banners', 'bannerExpired'])
  },
  async created() {
    if(this.banners.length === 0 || new Date().getDate() > this.bannerExpired){
      const res = await this.getBannerList();
      await this.$store.commit(`main/${MUT_SET_BANNERS}`, getCheckItems(res));
    }
  },
  mounted() {
    this.bannerRolling(true);
  },
  methods: {
    getBannerList(){
      return this.$store.dispatch(`main/${ACT_GET_BANNER_LIST}`);
    },
    movePage(isPrev){
      if(isPrev){
        const changeBanner = this.currentBanner -1;
        if(changeBanner < 0){
          this.currentBanner = (this.banners.length - 1);
        }else{
          this.currentBanner = changeBanner;
        }
      }else{
        const changeBanner = this.currentBanner + 1;
        if(changeBanner > (this.banners.length - 1)){
          this.currentBanner = 0;
        }else{
          this.currentBanner = changeBanner;
        }
      }
    },
    bannerRolling(init){
      if(init){
        this.interval = setInterval(()=>{
          this.movePage(false);
        },4000);
      }else{
        if(this.interval){
          clearInterval(this.interval);
          this.interval = null;
        }
      }
    }
  }
}
</script>