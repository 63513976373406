<template>
  <div class="main-section" data-aos="fade-up" data-aos-anchor-placement="top-bottom">
    <!-- 소상공인 커뮤니티 -->
    <div class="section-community section-item">
      <div class="title-area">
        <small class="bold small-text">디지털 전환 솔루션</small>
        <h4 class="title">온라인 진출 지원사례</h4>
        <p class="subtext">디지털 전환의 솔루션을 담은<br/>온라인 진출 지원의 사례를 확인하세요</p>
      </div>
      <router-link to="/community?ctgrCode=1007001" class="btn btn-white btn-link">
        <span class="text text-primary">함께하기</span>
      </router-link>
      <div class="section-community-image">
        <img src="../../../assets/mobile/img/main/section_community_image.jpg" alt="" />
      </div>
    </div>
  </div>

</template>
<script>
import {aosInit} from '../../../assets/js/aos.config';

export default {
  name: 'MobileSection2_1',
  methods: {},
  created() {
    aosInit();
  }
}
</script>