<template>
  <div>
    <mobile-section1 v-if="sectGrpId === 1"/>
    <mobile-section2_1 v-if="sectGrpId === 2"/>
    <mobile-section2_2 v-if="sectGrpId === 2"/>
    <mobile-section3 v-if="sectGrpId === 3"/>
    <mobile-section4 v-if="sectGrpId === 4"/>
    <!--<mobile-section5_1 v-if="sectGrpId === 5"/>-->
    <mobile-section5_2 v-if="sectGrpId === 5"/>
    <mobile-section6 v-if="sectGrpId === 6"/>
  </div>
</template>

<script>
import MobileSection1 from './section/mobile/MobileSection1';
import MobileSection2_1 from './section/mobile/MobileSection2_1';
import MobileSection2_2 from './section/mobile/MobileSection2_2';
import MobileSection3 from './section/mobile/MobileSection3';
import MobileSection4 from './section/mobile/MobileSection4';
import MobileSection5_1 from './section/mobile/MobileSection5_1';
import MobileSection5_2 from './section/mobile/MobileSection5_2';
import MobileSection6 from './section/mobile/MobileSection6';

export default {
  name: 'MobileTargetSection',
  components:{
    MobileSection1,
    MobileSection2_1,
    MobileSection2_2,
    MobileSection3,
    MobileSection4,
    MobileSection5_1,
    MobileSection5_2,
    MobileSection6
  },
  props:{
    sectGrpId: Number
  }
}
</script>
