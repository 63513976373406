<template>
  <!-- 소상공인 맞춤교육 -->
  <div class="main-section section-component">

    <div class="section-community section-item long-ht" style="margin-top: 20px">
      <div class="title-area">
        <h4 class="title">판로TV</h4>
        <p class="subtext">『중소기업유통센터』의 소상공인 디지털 성공을 위한 판로방송!<br/>
          "나침:판 | 판:소리 | 성장:판 | 메뉴:판 | 완:판"으로 구성된 5단계의<br/>
          소상공인 맞춤형 판로 소통 방송을 소개합니다.
        </p>
      </div>
      <router-link to="/broadcast" class="btn btn-white btn-link">
        <span class="text text-primary">함께하기</span>
      </router-link>
      <div class="section-community-image">
        <img src="../../assets/img/main/main_broadcast_image2.png" alt="판로방송 바로가기">
      </div>
    </div>
<!--
    <div class="section-community section-item long-ht" style="margin-top: 20px">
      <div class="title-area">
        <h4 class="title">온라인 진출 배움터</h4>
        <p class="subtext">『중소기업유통센터』 의 실전 전문가 육성 플랫폼 <br>"소상공인분들을 『디지털 전문가』 로 만들어 드립니다."</p>
      </div>
      <router-link to="/education" class="btn btn-white btn-link">
        <span class="text text-primary">함께하기</span>
      </router-link>
      <div class="section-community-image">
        <img src="../../assets/img/main/main_edu_image_v1.png" alt="">
      </div>
    </div>
-->

<!--    <div class="section-edu section-item">-->
<!--      <div class="title-area">-->
<!--        <small class="bold small-text">소상공인 맞춤교육</small>-->
<!--        <h4 class="title">슬기로운 디지털 전환,  소상공인 맞춤교육</h4>-->
<!--      </div>-->
<!--      <ul class="edu-video-area">-->
<!--        <li v-for="(item, idx) in items" class="edu-video-item" :key="idx">-->
<!--          <div class="edu-thumb">-->
<!--            <a :href="item.eduUrl" class="image-link" target="_blank"><img :src="item.imgPtUrl" alt=""></a>-->
<!--          </div>-->
<!--          <div class="edu-content">-->
<!--            <h5 class="edu-title">-->
<!--              <a :href="item.eduUrl" class="title-link" data-anim="true" target="_blank">{{ item.eduBannrTit }}</a></h5>-->
<!--            <p class="edu-description">-->
<!--              <span class="small-text bold">{{ item.eduBannrCtgr }}</span>-->
<!--              <i class="icon-edu-playtime"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">edu_playtime.svg</font></font></i>-->
<!--              <span class="edu-playtime">{{ item.eduPlayHhmm }}</span>-->
<!--            </p>-->
<!--            <div class="edu-playbutton">-->
<!--              <a :href="item.eduUrl" class="btn-edu-play" target="_blank">-->
<!--                <i class="icon-play">재생하기</i>-->
<!--              </a>-->
<!--            </div>-->
<!--          </div>-->
<!--        </li>-->
<!--      </ul>-->
<!--    </div>-->
    <!-- 알려드려요 - 정보놀이터, 우리소상공인 -->
    <div class="section-information section-item">
      <div class="notice-contents">
        <article class="notice-card notice-infoplayground">
          <router-link to="/playground?ctgrCode=1001001" class="card-link"><span class="screen-out">자세히 보기</span></router-link>
          <div class="card-inner">
            <header class="card-header">
              <h4 class="header"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;color: black">소상공인 뉴스</font></font></h4>
            </header>
            <div class="card-body">
              <!-- <h5 class="title">{{ playground.plygrndTit }}</h5>
              <p class="tag">{{ playground.tags }}</p> -->
            </div>
          </div>
          <div class="card-image">
            <!--<img :src="playground.rprsImgPtUrl" alt="">-->
            <img src="../../assets/img/main/funnycontents.png" alt="">
          </div>
        </article>
        <article class="notice-card notice-infoplayground">
          <router-link :to="{name: 'Issue'}" class="card-link"><span class="screen-out"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">자세히 보기</font></font></span></router-link>
          <div class="card-inner">
            <header class="card-header">
              <h4 class="header"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;color: black">소상공인 포스트</font></font></h4>
              <div class="issue">
               <!--  <small class="issue-title"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">issue</font></font></small>
                <p class="issue-num">{{ issue.issueNo }}</p> -->
              </div>
            </header>
            <div class="card-body">
              <!--<h5 class="title">{{ issue.issueTit }}</h5>-->
              <!--<p class="view">
                <i class="icon-view"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">메인/view_icon.svg</font></font></i>
                <span class="text">{{ issue.inqCnt }}</span>
              </p>-->
            </div>
          </div>
          <div class="card-image">
            <!--<img :src="issue.rprsImgPtUrl" alt="">-->
            <img src="../../assets/img/main/sp_renew.png" alt="">
          </div>
        </article>
      </div>
    </div>
    <a href="https://mall.valuebuy.kr/">
      <img src="../../assets/img/common/wing-banner.png" style="position: absolute; top: 6.1%; left: 98%; width: 110px; height: 318px;">
    </a>
  </div>
</template>
<script>
import {ACT_GET_EDU_BANNER_LIST, ACT_GET_ISSUE_LIST, ACT_GET_PLAYGROUND_LIST} from '../../store/_act_consts';
import {getItem, getItems, lengthCheck, numberComma, setParams, viewTags} from '../../assets/js/utils';
import {MUT_SHOW_ALERT} from '../../store/_mut_consts';

export default {
  name: 'Section5',
  data:() => ({
    playground: {
      plygrndId: 0,
      plygrndCtgr: '',
      plygrndTit: '',
      rprsImgPtUrl: '',
      tags: '',
    },
    issue: {
      issueId: 0,
      issueNo: 0,
      issueTit: '',
      issueSmlTit: '',
      rprsImgPtUrl: '',
      inqCnt: 0
    },
    items: []
  }),
  created() {
    this.getEduBanners();
    this.getPlaygroundTop();
    this.getIssueTop();
  },
  methods: {
    getEduBanners(){
      this.$store.dispatch(`main/${ACT_GET_EDU_BANNER_LIST}`,{
        pageSize: 1
      }).then(res=>{
        if(lengthCheck(res)){
          this.items = getItems(res);
        }else {
          this.items = [];
        }
      }).catch(e=>{
        console.error(e);
        this.items = [];
      })
    },
    getPlaygroundTop(){
      this.$store.dispatch(`information/${ACT_GET_PLAYGROUND_LIST}`, {
        pageSize: 1
      }).then(res=>{
        if(lengthCheck(res)){
          const item = getItem(res);

          setParams(this.playground, item);
          this.playground.tags = viewTags(item, 'plygrndTag');
        } else {
          this.playground.plygrndId = 0;
        }
      }).catch(e=>{
        console.error(e);
      });
    },
    getIssueTop(){
      this.$store.dispatch(`information/${ACT_GET_ISSUE_LIST}`, {
        pageSize: 1
      }).then(res=>{
        if(lengthCheck(res)){
          const item = getItem(res);
          setParams(this.issue, item);
          if(item.inqCnt != null) {
            this.issue.inqCnt = numberComma(item.inqCnt);
          }
        } else{
          this.issue.issueId = 0;
        }
      }).catch(e=>{
        console.error(e);
      })
    }
  }
}
</script>