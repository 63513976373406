<template>
  <div>
    <section1 v-if="sectGrpId === 1"/>
    <section2 v-if="sectGrpId === 2"/>
    <section3 v-if="sectGrpId === 3"/>
    <section4 v-if="sectGrpId === 4"/>
    <section5 v-if="sectGrpId === 5"/>
    <section6 v-if="sectGrpId === 6"/>
  </div>
</template>

<script>
import Section1 from './section/Section1';
import Section2 from './section/Section2';
import Section3 from './section/Section3';
import Section4 from './section/Section4';
import Section5 from './section/Section5';
import Section6 from './section/Section6';

export default {
  name: 'TargetSection',
  components:{
    Section1,
    Section2,
    Section3,
    Section4,
    Section5,
    Section6
  },
  props:{
    sectGrpId: Number
  }
}
</script>
