<template>
  <div>
    <target-section v-for="(item, idx) in sectionGroups" :sect-grp-id="item.sectGrpId" :key="idx"/>
  </div>
</template>
<script>
import TargetSection from './TargetSection';
import {mapGetters} from 'vuex';
import {ACT_GET_SECTION_GROUP_LIST} from '../store/_act_consts';
import {getItems, lengthCheck, tryResCallback} from '../assets/js/utils';
import {MUT_SET_SECTION_GROUPS} from '../store/_mut_consts';
export default {
  name: 'SectionGroup',
  components: {TargetSection},
  data: () => ({}),
  created() {
    this.getSectionGroups();
  },
  computed: {
    ...mapGetters('main', ['sectionGroups', 'sectionExpired'])
  },
  methods: {
    getSectionGroups() {
      if(this.sectionGroups.length === 0 || new Date().getTime() > this.sectionExpired){
        try{
          this.$store.dispatch(`main/${ACT_GET_SECTION_GROUP_LIST}`).then(res => {
            tryResCallback(this.tryCount, res, this.getSectionGroups, this.setSectionGroups);
          });
        }catch (e){
          console.error(e);
        }
      }
    },
    setSectionGroups(res) {
      if(lengthCheck(res)){
        const items = getItems(res);
        this.$store.commit(`main/${MUT_SET_SECTION_GROUPS}`, items.map(x => ({sectGrpId: x.sectGrpId})));
      }
    }
  }
}
</script>
