<template>
  <!-- 홍보 - 스튜디오 예약 -->
  <div class="main-section section-component">
    <div class="section-studio section-item">
      <div class="title-area">
        <small class="bold small-text">스튜디오 안내 및 예약</small>
        <h4 class="title">소상공인 전용<br>라이브 스튜디오 예약하기</h4>
      </div>
      <router-link to="/studio" class="btn btn-white btn-revse">
        <span class="text text-primary">예약하기</span>
      </router-link>

      <div class="section-studio-image">
        <img src="../../assets/img/main/section_studio.jpg" alt="">
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'Section3'
}
</script>