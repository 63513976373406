<template>
  <div class="main-banner swiper js-banner-swiper">
    <div class="banner-background"></div>
      <swiper v-if="isSlideReady" class="swiper" ref="mySwiper" :options="swiperOptions">
        <swiper-slide v-for="(item, idx) in banners" :key="idx" >
          <div class="banner-list">
            <div class="banner-item">
              <div class="banner-content">
                <h5 class="banner-sub-title" data-swiper-parallax="-50">{{ item.bannrSubTit }}</h5>
                <h1 class="banner-title" data-swiper-parallax="-100">{{ item.bannrTit }}</h1>
              </div>
              <div class="banner-image">
                <img :src="item.imgPtUrl" alt="">
              </div>
              <a :href="item.mvmnptUrl" class="banner-link" title="새창" target="_blank">바로가기</a>
            </div>
          </div>
        </swiper-slide>
        <div class="swiper-pagination banner-paging" slot="pagination"></div>
        <div class="banner-move-buttons">
          <button class="banner-move-button banner-move-prev" type="button">
            <i class="icon-arrow"></i>
          </button>
          <button class="banner-move-button banner-move-next" type="button">
            <i class="icon-arrow"></i>
          </button>
        </div>
      </swiper>
      
      
      <!-- 홍보동영상 보기 버튼 추가 S (20220803 hib) -->
      <div class="banner-video-modal" @click="onClickVideoModal">
      <img class="banner-video-img" src="../assets/mobile/img/common/icon_video.png">홍보동영상 보기</div>
      <!-- 홍보동영상 보기 버튼 추가 E (20220803 hib) -->
  </div>
</template>
<script>
import {mapGetters} from 'vuex';
import {ACT_GET_BANNER_LIST} from '../store/_act_consts';
import {getCheckItems} from '../assets/js/utils';
import {MUT_SET_BANNERS, MUT_SHOW_COMMON_VIDEO_MODAL} from '../store/_mut_consts';
import {Swiper, SwiperSlide} from 'vue-awesome-swiper';
import {swiperOptions} from '../assets/js/swiper.config';

export default {
  name: 'MobileBanner',
  components: {
    Swiper,
    SwiperSlide
  },
  data: () => ({
    currentBanner: 0,
    swiperOptions: swiperOptions(),
    isSlideReady: false,
  }),
  computed: {
    ...mapGetters('main', ['banners', 'bannerExpired']),
    swiper() {
      return this.$refs.mySwiper.$swiper;
    }
  },
  async created() {
    if(this.banners.length === 0 || new Date().getDate() > this.bannerExpired){
      const res = await this.getBannerList();
      await this.$store.commit(`main/${MUT_SET_BANNERS}`, getCheckItems(res));
    }
    this.isSlideReady = true;
  },
  methods: {
    getBannerList(){
      return this.$store.dispatch(`main/${ACT_GET_BANNER_LIST}`);
    },
    movePage(isPrev){
      if(isPrev){
        const changeBanner = this.currentBanner -1;
        if(changeBanner < 0){
          this.currentBanner = (this.banners.length - 1);
        }else{
          this.currentBanner = changeBanner;
        }
      }else{
        const changeBanner = this.currentBanner +1;
        if(changeBanner > (this.banners.length - 1)){
          this.currentBanner = 0;
        }else{
          this.currentBanner = changeBanner;
        }
      }
    },
    /* 홍보동영상 보기 모달창 실행 (20220803 hib) */
    onClickVideoModal(){
      this.$store.commit(`video/${MUT_SHOW_COMMON_VIDEO_MODAL}`,{});
    }
  }
}
</script>