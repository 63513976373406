<template>
  <div class="main-section section-component">
    <!-- 온라인 판로 지원사업 -->
    <div class="section-notice section-item notice-primary">
      <img src="../../assets/img/main/online_sb.png" style="width: 100%;position: absolute;">
      <a href="/support" class="notice-link" style="justify-content: space-between">
        <div class="title-area" style="padding: 0">
          <small class="bold small-text">온라인 판로 지원사업</small>
          <h4 class="title">다양한 판로 지원사업을<br>확인하세요</h4>
        </div>
        <i class="icon-link">소상공인 지원사업 이동하기</i>
      </a>
    </div>
    <!-- 구독경제 -->
    <div class="section-notice section-item">
      <img src="../../../src/assets/img/main/subscribe.png" style="width: 100%;position: absolute;">
      <ul class="notice-list">
        <li v-if="community !== null" class="notice-item">
          <router-link :to="`/community/${community.comunBltartclId}/view?ctgrCode=1007007`" class="notice-link">
            <i class="icon-link"></i>
          </router-link>
          <div class="title-area">
            <small class="bold small-text"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">소상공인 구독경제 길라잡이</font></font></small>
            <h4 class="title">{{ community.comunBltartclTit }}</h4>
          </div>
        </li>

        <li v-else class="notice-item">
          <router-link to="/community?ctgrCode=1007007" class="notice-link">
            <i class="icon-link"></i>
          </router-link>
          <div class="title-area">
            <small class="bold small-text"><font style="vertical-align: inherit;"><font style="vertical-align: inherit;">구독경제</font></font></small>
          </div>
        </li>
      </ul>

      <ol class="section-notice-paging">
        <li v-for="(item, idx) in items" :class="{'selected' : idx === currentCommunity}" :key="idx">
          <font style="vertical-align: inherit;"><font style="vertical-align: inherit;">{{idx}}</font></font>
        </li>
      </ol>

      <div v-if="items.length > 1" class="section-notice-arrow">
        <button type="button" class="btn-arrow"  @click="movePage(true)">
          <i class="icon-arrow-prev">이전 페이지</i>
        </button>
        <button type="button" class="btn-arrow" @click="movePage(false)">
          <i class="icon-arrow-next">다음 페이지</i>
        </button>
      </div>
    </div>
  </div>
</template>
<script>
import {ACT_GET_ISSUE_LIST, ACT_GET_SB_COMMUNITY_LIST} from '../../store/_act_consts';
import {getItems, lengthCheck} from '../../assets/js/utils';

export default {
  name: 'Section1',
  data: ()=> ({
    items: [],
    // currentIssue: 0,
    currentCommunity: 0
  }),
  computed:{
    // issue(){
    //   if(!this.items[this.currentIssue]) return null;
    //   return this.items[this.currentIssue];
    // },
    community(){
      if(!this.items[this.currentCommunity]) return null;
      return this.items[this.currentCommunity];
    }
  },
  created() {
    // this.getIssueList();
    this.getSbCommunityList();
  },
  methods: {
    // getIssueList() {
    //   this.$store.dispatch(`information/${ACT_GET_ISSUE_LIST}`, {
    //     pageSize: 3,
    //   }).then(res=>{
    //     if(lengthCheck(res)){
    //       this.items = getItems(res);
    //     } else {
    //       this.items = [];
    //     }
    //   }).catch(e=>{
    //     console.error(e);
    //     this.items = [];
    //   })
    // },
    getSbCommunityList() {
      this.$store.dispatch(`community/${ACT_GET_SB_COMMUNITY_LIST}`, {
        pageSize: 4,
        comunCtgrDcd: 1007007
      }).then(res=>{
        if(lengthCheck(res)){
          this.items = getItems(res);
        } else {
          this.items = [];
        }
      }).catch(e=>{
        console.error(e);
        this.items = [];
      })
    },
    movePageIssue(isPrev){
      if(isPrev){
        const changeIssue = this.currentIssue - 1;
        if(changeIssue < 0){
          this.currentIssue = (this.items.length - 1);
        } else {
          this.currentIssue = changeIssue;
        }
      } else {
        const changeIssue = this.currentIssue + 1;
        if(changeIssue > (this.items.length - 1)){
          this.currentIssue = 0;
        } else {
          this.currentIssue = changeIssue;
        }
      }
    },
    movePage(isPrev){
      if(isPrev){
        const changeCommunity = this.currentCommunity - 1;
        if(changeCommunity < 0){
          this.currentCommunity = (this.items.length - 1);
        } else {
          this.currentCommunity = changeCommunity;
        }
      } else {
        const changeCommunity = this.currentCommunity + 1;
        if(changeCommunity > (this.items.length - 1)){
          this.currentCommunity = 0;
        } else {
          this.currentCommunity = changeCommunity;
        }
      }
    }
  }
}
</script>